import CryptoJS from 'crypto-js/crypto-js'

let randomNumber = (len) => {
    let chars = "0123456789";
    let maxPos = chars.length;
    let str = "";
    for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
};

// const enkey = crypto.enc.Utf8.parse("Yvsfo6be97XM1da5");
// const eniv = crypto.enc.Utf8.parse("8kH3xMi2");


const key = CryptoJS.enc.Utf8.parse("v0VXjplfhD2w8dQCyAiM5etgBNoZsm1G");//""中与后台一样  密码
const iv = CryptoJS.enc.Utf8.parse('2YQRdTXwE3P6Dh4B');//""中与后台一样  偏移量


let Encrypt = (word) => {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString(); // 使用 toString() 方法将加密结果转换为 Base64 字符串
}


let EncryptMsg = (msg) => {
    return crypto.DES.encrypt(msg, enkey, {
        iv: eniv,
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7,
    }).ciphertext.toString();
};


let changeMenu = (menu) => {
    menu.forEach((item) => {
        // console.log(item)
        item.meta = {};
        item.meta.title = item.title;
        item.meta.id = item.id;
        delete item.title;
        item.path = item.route;
        if (item.subList) {
            item.children = item.subList;
            item.component = "";
        }
        delete item.subList;
        // item.name = item.route.match(//)
        item.component = item.route;
        if (item.children && item.children.length >= 1) {
            changeMenu(item.children);
        }
    });

    return menu;
}

// 格式化数据，子集没有children，子集就没有children这个属性
let formateArr = (data) => {
    // console.log(data,"数据")
    for (var i = 0; i < data.length > 0; i++) {
        // console.log(data[i],"---")
        if (data[i].children == null || data[i].children.length <= 0) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
        } else {
            // children若不为空数组，则继续 递归调用 本方法
            formateArr(data[i].children);
        }
    }
    return data;
}

// 权限菜单递归
let getMenuTree = (pid, arr, res) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].pid == pid) {
            res.push(arr[i]);
            arr.splice(i, 1);
            i--;
        }
    }
    res.map(r => {
        r.children = [];
        getMenuTree(r.id, arr, r.children);
        if (r.children.length == 0) {
            delete r.children;
        }
    });
    return res;
}

let getImgUrl = (url) => {
    const regExp = /\/2023.*/;
    const result = url.match(regExp)[0];
    return result
}

let isObjEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}


let throttle = (func, delay) => {
    let canRun = true;
    return function () {
        if (canRun) {
            func.apply(this, arguments);
            canRun = false;
            setTimeout(() => {
                canRun = true;
            }, delay);
        }
    };
}

export { throttle, randomNumber, EncryptMsg, changeMenu, formateArr, getMenuTree, getImgUrl, isObjEmpty, Encrypt };