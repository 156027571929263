<template>
    <div>
        <editor v-model="myValue" placeholder="输入内容" :init="init" :disabled="disabled" :id="tinymceId"></editor>
    </div>
</template>
<script setup>
import { reactive, ref, onMounted, watch, onUnmounted } from 'vue'
import tinymce from 'tinymce/tinymce'
import 'tinymce/skins/content/default/content.css'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default'
import 'tinymce/models/dom'
import "tinymce/icons/default/icons"
import "tinymce/plugins/table" //表格插件
import "tinymce/plugins/lists" //无序、有序列表插件
import "tinymce/plugins/image" //图标插件
import "tinymce/plugins/media" //插入媒体插件
import "tinymce/plugins/emoticons"
// import "tinymce/plugins/paste"
import "tinymce/plugins/autosave"
import "tinymce/plugins/preview"
import "tinymce/plugins/code"
import 'tinymce/icons/default/icons.min.js'


import axios from "axios";
import sha1 from "crypto-js/sha1";
import dayjs from "dayjs";
import { randomNumber } from "@/utils/utils.js";

const emits = defineEmits(["getContent", "setContent"])
const props = defineProps({
    value: {
        type: String,
        default: () => {
            return ""
        },
    },
    width: {
        type: String,
        default: "100%",
    },
    height: {
        type: Number,
        default: 600,
    },
    baseUrl: {
        type: String,
        default: "",
    },
    disabled: {
        type: Boolean,
        default: false,
    },

    plugins: {
        type: [String, Array],
        default: "lists code table media image autosave preview",
    },//必填
    toolbar: {
        type: [String, Array],
        default:
            "code codesample bold italic underline alignleft aligncenter alignright alignjustify | undo redo | formatselect | fontselect | fontsizeselect | forecolor backcolor | bullist numlist outdent indent |lists link table code |image media| removeformat | restoredraft |preview",
    },//必填
})
//用于接收外部传递进来的富文本

// console.log(props, "接收到的数据")

const myValue = ref(props.value)
const tinymceId = ref("vue-tinymce-" + +new Date() + ((Math.random() * 1000).toFixed(0) + ""))
//定义一个对象 init初始化
const init = reactive({
    selector: '#' + tinymceId.value, //富文本编辑器的id,
    language_url: "/tinymce/langs/zh_CN.js", // 语言包的路径，具体路径看自己的项目，文档后面附上中文js文件
    language: "zh_CN", //语言
    skin_url: "/tinymce/skins/ui/oxide", // skin路径，具体路径看自己的项目
    width: props.width,
    height: props.height, //编辑器高度
    // inline:true,
    resize:false,
    max_height:props.height,
    branding: false, //是否禁用“Powered by TinyMCE”
    menubar: true, //顶部菜单栏显示
    image_dimensions: false, //去除宽高属性
    menubar: "edit view insert format",
    plugins: props.plugins,  //这里的数据是在props里面就定义好了的
    toolbar: props.toolbar, //这里的数据是在props里面就定义好了的
    font_formats: 'Arial=arial,helvetica,sans-serif; 宋体=SimSun; 微软雅黑=Microsoft Yahei; Impact=impact,chicago;', //字体
    fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 64px 72px', //文字大小
    // paste_convert_word_fake_lists: false, // 插入word文档需要该属性
    paste_webkit_styles: "all",
    paste_merge_formats: true,
    nonbreaking_force_tab: false,
    paste_auto_cleanup_on_paste: false,
    file_picker_types: 'file',
    paste_data_image: true,//可以粘贴图片
    content_css: '/tinymce/skins/content/default/content.css', //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入
    relative_urls: false,//不使用相对路径
    remove_script_host: false,
    extended_valid_elements: 'img[src|alt|width|height]',
    // convert_urls: false,
    init_instance_callback: function (editor) {
        // console.log(props,"传入数据")
        editor.setContent(props.value)
    },

    images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {

        if (blobInfo.blob().size / 1024 / 1024 > 2) {

            reject({ message: '上传失败，图片大小请控制在 2M 以内', remove: true })

            return

        } else {

            // resolve("https://img1.baidu.com/it/u=22553051,3834724655&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500")
            // consoe.log(success,"success")
            let timestamp = dayjs().unix();
            let nonce = randomNumber(6); //6位随机数
            let appid = randomNumber(6);
            let noSha1Data = appid + "" + timestamp + nonce + "" + process.env.VUE_APP_SERECT;
            const formData = new FormData();
            formData.append("file", blobInfo.blob());
            axios.post(process.env.VUE_APP_API_URL + "filelocalhost/uploadFile", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "authority-token": localStorage.getItem("token"),
                    timestamp: timestamp,
                    nonce: nonce,
                    appid: appid,
                    sign: sha1(noSha1Data),

                },

            })

                .then((response) => {
                    console.log(response, "res")
                    let resData = response.data;

                    if (resData.code === 200) {
                        resolve(resData.data.fileName)
                    }
                })
                .catch((error) => {
                    // 处理错误
                    console.log(error, "错误");

                });
        }

    }),
})
//监听外部传递进来的的数据变化
watch(
    () => props.value,
    () => {
        myValue.value = props.value
        emits("getContent", myValue.value)
    }
)
//监听富文本中的数据变化
watch(
    () => myValue.value,
    () => {
        emits("getContent", myValue.value)
    }
)
//在onMounted中初始化编辑器
let isMounted = false;
onMounted(() => {
    if (!isMounted) {
        tinymce.init({})
    }

})
</script>

