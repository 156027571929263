import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@arco-design/web-vue/dist/arco.css';
import "@arco-themes/vue-tffg/theme.css"
// import "normalize.css"


import TEditor from '@/components/TEditor.vue';

import myUplod from "@/components/uploadFile.vue"
import resourcePool from "@/components/resourcePool.vue"

const app = createApp(App);

import { getImgUrl, isObjEmpty } from "@/utils/utils";

import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
// 导入需要的语言包
import 'dayjs/locale/zh-cn'



// 应用插件
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

// 设置语言
dayjs.locale('zh-cn')

// 将dayjs实例挂载到全局
app.config.globalProperties.$dayjs = dayjs

app.config.globalProperties.getImgUrl = getImgUrl
app.config.globalProperties.isObjEmpty = isObjEmpty


import preventReClick from './utils/preventReClick';

// 导入全局组件
import myEcharts from "./components/echarts.vue"


import "./styles/digital.scss"
import "./styles/arco_modify.css"
app.component("TEditor", TEditor)
app.component("myUplod", myUplod)
app.component("resourcePool", resourcePool)
app.component("myEcharts", myEcharts)

app.use(store);
app.use(router);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(preventReClick);
app.mount('#app')
