<template>
    <a-upload accept="image/*,video/*"   :multiple="true" list-type="picture" :limit="limitNum"
        :custom-request="customRequest" image-preview />
    <!-- <a-button @click="getThing" type="success">点击</a-button> -->
</template>
  
<script setup>
import axios from "axios";
import sha1 from "crypto-js/sha1";
import dayjs from "dayjs";
import { randomNumber } from "@/utils/utils.js";
import { ref } from "vue"
import { IconUpload, IconFileAudio, IconClose, IconFaceFrownFill } from '@arco-design/web-vue/es/icon';
const emits = defineEmits(['onSuccess'])

const props = defineProps({
    limitNum: {
        type: Number,
        default: 0,
    }
})

const limitNum = ref(props.limitNum)

const customRequest = (option) => {
    const { onProgress, onError, onSuccess, fileItem, name } = option;

    let timestamp = dayjs().unix();
    let nonce = randomNumber(6); //6位随机数
    let appid = randomNumber(6);
    let noSha1Data = appid + "" + timestamp + nonce + "" + process.env.VUE_APP_SERECT;

    const formData = new FormData();
    formData.append("file", fileItem.file);
    axios
        .post(process.env.VUE_APP_API_URL + "filelocalhost/uploadFile", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "authority-token": localStorage.getItem("token"),
                timestamp: timestamp,
                nonce: nonce,
                appid: appid,
                sign: sha1(noSha1Data),
            },
            onUploadProgress: (event) => {
                let percent;
                if (event.total > 0) {
                    percent = (event.loaded / event.total) * 100;
                }
                onProgress(parseInt(percent, 10), event);
            },
        })
        .then((response) => {
            console.log(response, "res");
            //这里需要根据自己的接口实际返回值修改
            if (response.data.code === 200) {
                onSuccess(response.data.data);
                // 触发 on-success 事件
                // console.log(emits, "emits事件")
                emits('on-success', response.data);
            }


            if (response.data.code !== 200) {
                return onError(response.data.msg);
            }
        })
        .catch((error) => {
            // 处理错误
            console.log(error, "错误");
        });
};


</script>
  
  