import axios from "axios";
import sha1 from "crypto-js/sha1";
import dayjs from "dayjs"
import { Message, MessageBox } from "@arco-design/web-vue";



const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 15000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {

        console.log(config,"---")
        
        let timestamp = dayjs().unix();
        let nonce = randomNumber(6); //6位随机数
        let appid = randomNumber(6);
        let noSha1Data = appid + "" + timestamp + nonce + "" + process.env.VUE_APP_SERECT;

        config.headers["Content-Type"] = "multipart/form-data"
        config.headers["authority-token"] =
            localStorage.getItem("token") || store.getters.getToken;
        config.headers["timestamp"] = timestamp;
        config.headers["nonce"] = nonce;
        config.headers["appid"] = appid;
        config.headers["sign"] = sha1(noSha1Data);

        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (res.code === 200) {
            return res.data;
        } else {
            if (res.code) {
                if (res.code === 1004) {
                    localStorage.clear();
                }
                if (res.code === 996) {
                    localStorage.clear();
                    router.push({
                        path: "/login"
                    })
                }
                // -200表示升级那些
                if (res.code === -200) {
                    // MessageBox.alert({
                    //     message: res.msg,

                    // })
                }

            } else {
                return res;
            }
        }
    },
    (error) => {
        console.log("err" + error); // for debug
        if (
            error.code === "ECONNABORTED" ||
            error.message === "Network Error" ||
            error.message.includes("timeout")
        ) {
            Message({
                message: "请求超时，请稍后重试",
                type: "error",
                duration: 3 * 1000,
            });
        } else {
            Message({
                showClose: true,
                message: error.message,
                type: "error",
                duration: 5 * 1000,
            });
        }

        return Promise.reject(error);
    }
);

export default service;
