<template>
    <div>
        <a-modal :visible="isShow" width="80%" @ok="getImage" @cancel="getCancel" title-align="start">
            <template #title>
                <div class="modal-title">
                    <div class="icon midVerti"></div>
                    <div> 资源库</div>
                </div>
            </template>
            <div class="resorcePool">
                <div class="upload">
                    <myUplod class="midVerti" :uploadKey="key" @on-success="saveImags"></myUplod>
                </div>
                <div class="imageBox">
                    <div class="item" @click="selected(item)" v-for="(item, index) in  list " :key="index">
                        <video :class="item.active ? 'active' : ''" controls :src="item.fileName"
                            v-if="item.fileName.includes('mp4')" style="width: 200px;height: 170px;"></video>
                        <a-image :preview="false" :class="item.active ? 'active' : ''" v-else
                            :src="item.fileName + '.tmp.png'" width="170px" height="170px" fit="cover">
                            <template #extra>
                                <div class="actions">
                                    <span style="cursor: pointer;" class="action"
                                        @click.stop="copyUrls(item)"><icon-copy /></span>
                                </div>
                            </template>
                        </a-image>
                        <a-link status="danger" @click="delImage(item)">删除</a-link>
                    </div>
                </div>
                <div class="pagesBtns">
                    <a-button type="primary" @click="prePage">上一页</a-button>
                    <a-button type="primary" @click="nextPage">下一页</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>

import { reactive, toRefs, onMounted } from "vue";
import { getAListOfResources, delAListOfResources } from "@/api/index.js"
import { Message } from "@arco-design/web-vue";

export default {
    name: 'resourcePool',
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const state = reactive({
            list: [],
            currentPage: 1,
            currentMedia: {},
            key: 1,
        })
        // const emits = defineEmits(["getImages"])

        let isMounted = false;

        onMounted(() => {
            if (!isMounted) {
                getFiles();
            }

        })

        const getFiles = () => {
            let data = {
                page: state.currentPage,
                pageSize: 20
            }
            getAListOfResources(data).then(res => {
                let mp4 = [];
                let img = [];
                res.forEach(element => {
                    element.active = false;
                });
                state.list = res;
            })
        }

        // 选中的图
        const selected = (row) => {
            // console.log(row)
            state.currentMedia = row;
            state.list.forEach(item => {
                if (item.id === row.id) {
                    item.active = true;
                } else {
                    item.active = false;
                }
            })
        }

        // 保存图片  实际上用不上，最多这里调一下列表页
        const saveImags = (r) => {
            state.key += 1;
            getFiles();
        }

        const getImage = () => {
            // console.log(emits, "---")
            // console.log("关闭")
            state.list.forEach(item => {
                item.active = false;
            })
            context.emit("getImages", state.currentMedia)
        }

        const getCancel = () => {
            // console.log("关闭")
            state.list.forEach(item => {
                item.active = false;
            })
            context.emit("closePool")

        }

        const prePage = () => {
            state.isLoading = true;
            state.currentPage -= 1;
            if (state.currentPage > 1) {
                let data = {
                    pageSize: 20,
                    page: state.currentPage
                }
                getAListOfResources(data).then(res => {
                    // console.log(res, "所有的资源列表")
                    res.forEach(element => {
                        element.active = false;
                    });
                    state.list = res;
                })
            }
        }

        // 下一页
        const nextPage = () => {
            state.isLoading = true;
            state.currentPage += 1;
            let data = {

                pageSize: 20,
                page: state.currentPage
            }
            getAListOfResources(data).then(res => {
                // console.log(res, "所有的资源列表")
                if (res.length > 0) {
                    res.forEach(element => {
                        element.active = false;
                    });
                    state.list = res;
                } else {
                    Message.error({
                        content: "暂无更多数据"
                    })
                }

                state.isLoading = false;

            })
        }

        const delImage = (row) => {
            let data = {
                id: row.id
            }
            delAListOfResources(data).then(res => {
                if (res) {
                    getFiles();
                }
            })
        }


        const copyUrls = (item) => {
            console.log(item)
            copyTextToClipboard(item.fileName)
        }

        function copyTextToClipboard(text) {

            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        Message.success({
                            content: "图片链接复制成功！"
                        })
                    })
                    .catch(() => {
                        Message.error({
                            content: "链接复制失败"
                        })
                    })
            } else {
                // 创建text area
                const textArea = document.createElement('textarea')
                textArea.value = text
                // 使text area不在viewport，同时设置不可见
                document.body.appendChild(textArea)
                textArea.focus()
                textArea.select()
                return new Promise((resolve, reject) => {
                    // 执行复制命令并移除文本框
                    document.execCommand('copy') ? resolve() : reject(new Error('出错了'))
                    textArea.remove()
                }).then(
                    () => {
                        Message.success({
                            content: "图片链接复制成功！"
                        })
                    },
                    () => {
                        Message.error({
                            content: "链接复制失败"
                        })
                    }
                )


                // navigator.clipboard.writeText(text)
                //     .then(() => {
                //         console.log('');
                //         Message.success({
                //             content: "图片链接复制成功！"
                //         })
                //     })
                //     .catch((error) => {
                //         console.error('链接复制失败 ', error);
                //         Message.error({
                //             content: "链接复制失败"
                //         })
                //     });
            }
        }

        return {
            copyTextToClipboard,
            copyUrls,
            delImage,
            ...toRefs(state),
            getFiles,
            selected,
            saveImags,
            getImage,
            getCancel,
            prePage,
            nextPage
        }
    }
}
</script>
<style lang="scss" scoped>
.resorcePool {
    // max-width: 1250px;
    height: 700px;
    width: 100%;
}

.upload {
    width: 100%;
    height: 70px;
}

.imageBox {
    // width: 1050px;
    display: flex;
    flex-wrap: wrap;
    height: 550px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #333;
    }

    &::-webkit-scrollbar-corner {
        background: #dedede;
    }

    .item {
        min-width: 180px;
        min-height: 180px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .active {
        border: 4px solid #fb8b05;
    }
}
</style>