import request from "@/utils/request"
import http from "@/utils/upload.js"



export const userLogin = (data) => {
    return request({
        url: "/deploy/access",
        method: "POST",
        data
    })
}

// 得到新闻列表
export const getNewsList = (data) => {
    return request({
        url: "/farmdynamic/list",
        method: "POST",
        data
    })
}

// 添加新闻
export const createNews = (data) => {
    return request({
        url: "/farmdynamic/add",
        method: "POST",
        data
    })
}

// 更新新闻
export const updateNews = (data) => {
    return request({
        url: "/farmdynamic/update",
        method: "POST",
        data
    })
}

// 删除新闻
export const delNews = (data) => {
    return request({
        url: "/farmdynamic/del",
        method: "POST",
        data
    })
}


// 添加蜜源信息
export const addNectar = (data) => {
    return request({
        url: "/nectar/add",
        method: "POST",
        data
    })
}

// 更新蜜源信息
export const updateNectar = (data) => {
    return request({
        url: "/nectar/update",
        method: "POST",
        data
    })
}

// 蜜源列表
export const nectarList = (data) => {
    return request({
        url: "/nectar/list",
        method: "POST",
        data
    })
}

// 删除蜜源
export const delNectar = (data) => {
    return request({
        url: "/nectar/del",
        method: "POST",
        data
    })
}

// 删除蜜源的图片
export const delNectarImg = (data) => {
    return request({
        url: "/nectar/delImage",
        method: "POST",
        data
    })
}


// 得到资源文件列表
export const getAListOfResources = (data) => {
    return request({
        url: "/filelocalhost/listFile",
        method: "POST",
        data
    })
}

// 删除资源列表
export const delAListOfResources = (data) => {
    return request({
        url: "/filelocalhost/delFile",
        method: "POST",
        data
    })
}


// 添加蜂场
export const createFarm = (data) => {
    return request({
        url: "/farm/add",
        method: "POST",
        data
    })
}
// 更新蜂场信息
export const updateFarm = (data) => {
    return request({
        url: "/farm/update",
        method: "POST",
        data
    })
}

// 得到蜂场列表
export const farmList = (data) => {
    return request({
        url: "/farm/list",
        method: "POST",
        data
    })
}


// 删除蜂场
export const deleteFarm = (data) => {
    return request({
        url: "/farm/del",
        method: "POST",
        data
    })
}
// 删除蜂场的图
export const delFarmImg = (data) => {
    return request({
        url: "/farm/delImage",
        method: "POST",
        data
    })
}

// 得到蜂场详情
export const farmInfo = (data) => {
    return request({
        url: "/farm/info",
        method: "POST",
        data
    })
}



// 添加认养规格
export const addSpecification = (data) => {
    return request({
        url: "/farmmoney/add",
        method: "POST",
        data
    })
}

// 更新认养规格
export const updateSpecification = (data) => {
    return request({
        url: "/farmmoney/update",
        method: "POST",
        data
    })
}

// 规格列表
export const specificationList = (data) => {
    return request({
        url: "/farmmoney/list",
        method: "POST",
        data
    })
}

// 删除规格
export const delSpecification = (data) => {
    return request({
        url: "/farmmoney/del",
        method: "POST",
        data
    })
}


// 添加生产记录
export const addProdRecord = (data) => {
    return request({
        url: "/hiveprod/add",
        method: "POST",
        data
    })
}


// 更新生产记录
export const updateProdRecord = (data) => {
    return request({
        url: "/hiveprod/update",
        method: "POST",
        data
    })
}

// 删除生产记录里的图片
export const delProImage = (data) => {
    return request({
        url: "/hiveprod/delImage",
        method: "POST",
        data
    })
}

// 删除生产记录
export const delProItem = (data) => {
    return request({
        url: "/hiveprod/del",
        method: "POST",
        data
    })
}

 // 生产记录列表
export const proList = (data) => {
    return request({
        url: "/adopt/hiveprod/simpleList",
        method: "POST",
        data
    })
}


// 得到认养蜂箱列表
export const getHiveBoxList = (data) => {
    return request({
        url: "/hive/shelvesList",
        method: "POST",
        data
    })
}

// 发货信息添加
export const addExpressInfo = (data) => {
    return request({
        url: "/orderexpress/add",
        method: "POST",
        data
    })
}

// 更新发货信息
export const updateExpressInfo = (data) => {
    return request({
        url: "/orderexpress/update",
        method: "POST",
        data
    })
}

// 删除发货信息
export const deleteExpressInfo = (data) => {
    return request({
        url: "/orderexpress/del",
        method: "POST",
        data
    })
}

// 得到快递列表
export const getACourierList = (data) => {
    return request({
        url: "/orderexpress/simpleList",
        method: "POST",
        data
    })
}

// 得到处理过的地址
export const getprocessedAddress = (data) => {
    return request({
        url: "/area/dispose",
        method: "POST",
        data
    })
}

// 上传文件
export const uploadFile = (data) => {
    return http({
        url: "/filelocalhost/uploadFile",
        method: "POST",
        data
    })
}


// 同步蜂箱
export const syncHive = (data) => {
    return request({
        url: "/farm/syncHive",
        method: "POST",
        data
    })
}

// 迁移蜂箱到蜂场
export const moveHiveToFarm = (data) => {
    return request({
        url: "/hive/moveToFarm",
        method: "POST",
        data
    })
}

// 删除蜂箱的图片
export const delBoxImage = (data) => {
    return request({
        url: "/hive/delImage",
        method: "POST",
        data
    })
}

export const updateBoxInfo = (data) => {
    return request({
        url: "/hive/update",
        method: "POST",
        data
    })
}

// 得到当前蜂场的蜂箱
export const getFarmBox = (data) => {
    return request({
        url: "/hive/list",
        method: "POST",
        data
    })
}

// 得到用户列表
export const getUserList = (data) => {
    return request({
        url: "/user/list",
        method: "POST",
        data
    })
}

// 添加活动banner
export const addActBanner = (data) => {
    return request({
        url: "/activity/add",
        method: "POST",
        data
    })
}

// 得到活动列表
export const actBannerList = (data) => {
    return request({
        url: "/adopt/activity/list",
        method: "POST",
        data
    })
}

// 更新banner活动列表
export const updateActBanner = (data) => {
    return request({
        url: "/activity/update",
        method: "POST",
        data
    })
}

// 删除活动
export const delActBanner = (data) => {
    return request({
        url: "/activity/del",
        method: "POST",
        data
    })
}


// 添加活动里的预约、介绍、特权
export const addActOtherInfo = (data) => {
    return request({
        url: "/details/update",
        method: "POST",
        data
    })
}

// 得到活动里的预约、介绍、特权
export const actOtherInfo = (data) => {
    return request({
        url: "/adopt/details/info",
        method: "POST",
        data
    })
}

// 订单列表
export const orderList = (data) => {
    return request({
        url: "/order/list",
        method: "POST",
        data
    })
}

// 订单收货地址
export const orderAddress = (data) => {
    return request({
        url: "/order/takeAddress",
        method: "POST",
        data
    })
}

// 订单发货信息
export const deliverList = (data) => {
    return request({
        url: "/orderexpress/list",
        method: "POST",
        data
    })
}

export const deliverInfo = (data)=>{
    return request({
        url:"/orderexpress/sumDeliverCount",
        method:"POST",
        data
    })
}

// 得到收入统计
export const incomeStatistics = (data) => {
    return request({
        url: "/income/statistics",
        method: "POST",
        data
    })
}

// 得到收入月列表
export const getIncomeMonth = (data) => {
    return request({
        url: "/income/monthTimeList",
        method: "POST",
        data
    })
}

// 得到收入明细所需的数据列表
export const incomeDetails = (data) => {
    return request({
        url: "/order/incomeList",
        method: "POST",
        data
    })
}


// 得到分享的主题列表
export const getShareList = (data) => {
    return request({
        url: "/adopt/share/list",
        method: "POST",
        data
    })
}

// 添加分享的主题 
export const addShareCon = (data) => {
    return request({
        url: "/share/add",
        method: "POST",
        data
    })
}

// 删除主题图片
export const delShareImg = (data) => {
    return request({
        url: "/share/delImage",
        method: "POST",
        data
    })
}

// 删除分享主题
export const delShareCon = (data) => {
    return request({
        url: "/share/del",
        method: "POST",
        data
    })
}

// 得到账号信息
export const accountInfo = (data) => {
    return request({
        url: "/openAccount/info",
        method: "POST",
        data
    })
}


// 得到监控列表
export const monitorList = (data) => {
    return request({
        url: "/monitor/list",
        method: "POST",
        data
    })
}

export const monitorListGuest = (data)=>{
    return request({
        url: "/adopt/monitor/list",
        method: "POST",
        data
    })
}

// 添加监控信息
export const addMonitor = (data) => {
    return request({
        url: "/monitor/add",
        method: "POST",
        data
    })
}


// 更新监控信息
export const updateMonitor = (data) => {
    return request({
        url: "/monitor/update",
        method: "POST",
        data
    })
}

// 删除监控信息
export const deleteMonitor = (data) => {
    return request({
        url: "/monitor/del",
        method: "POST",
        data
    })
}


// 预置点位列表
export const presetsList = (data) => {
    return request({
        url: "/hive/monitor/presets/list",
        method: "POST",
        data
    })
}

// 预置位控制
export const setPresets = (data) => {
    return request({
        url: "/monitorpresets/control",
        method: "POST",
        data
    })
}

// 云台控制
export const panTiltControl = (data) => {
    return request({
        url: "/monitorpresets/stream",
        method: "POST",
        data
    })
}

// 得到指定用户的收货地址
export const getUserAddList = (data) => {
    return request({
        url: "/address/adminList",
        method: "POST",
        data
    })
}

// 气象仪列表
export const weatherList = (data) => {
    return request({
        url: "/farmweather/list",
        method: "POST",
        data
    })
}

// 添加新的气象仪
export const addWeather = (data) => {
    return request({
        url: "/farmweather/add",
        method: "POST",
        data
    })
}

// 更新气象仪
export const updateWeather = (data) => {
    return request({
        url: "/farmweather/update",
        method: "POST",
        data
    })
}

// 气象仪测试
export const weatherTest = (data) => {
    return request({
        url: "/farmweather/test",
        method: "POST",
        data
    })
}

// 删除气象仪
export const deleteWeather = (data) => {
    return request({
        url: "/farmweather/del",
        method: "POST",
        data
    })
}


// 删除活动里的图片
export const delBannerImg = (data) => {
    return request({
        url: "/activity/delImage",
        method: "POST",
        data
    })
}

// 更改密码
export const updatePsw = (data) => {
    return request({
        url: "/user/upPassword",
        method: "POST",
        data
    })
}


// 修改昵称
export const updateNickname = (data)=>{
    return request({
        url:"/user/upNickname",
        method:"POST",
        data
    })
}